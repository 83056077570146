import React, { useState, useEffect } from "react";
import useStyles from "./style";

// import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CartIcon } from "../../../assets/images/cart.svg";
import no_image_found from "../../../assets/images/no_image_found.png";
import { useHistory } from "react-router-dom";
import { postCall } from "../../../api/axios";
import { getValueFromCookie } from "../../../utils/cookies";
// import { Button } from "@mui/material";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const ProductGridView = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    product,
    productId,
    price,
    bpp_id,
    location_id,
    bpp_provider_id,
    bpp_provider_descriptor,
    show_quantity_button = true,
    onUpdateCart = () => { },
    handleAddToCart = () => { },
    getProductDetails,
    productLoading,
  } = props;
  const { id, descriptor, provider_details } = product;
  const { name: provider_name } = bpp_provider_descriptor;
  const { name: product_name, images, symbol } = descriptor;

  return (
    <div
      className={classes.productItemContainer}
      onClick={() => history.push(`/application/products?productId=${productId}`)}
    >

      <Card className="cs_card">
        <div className={classes.productCard}>
        <Card.Img className={classes.productImage}  variant="top" src={symbol ? symbol : no_image_found} />
        </div>
        <Card.Body>
          <span className="mb-2 seller_name">Seller - {provider_name}</span>
          <p className="product_title">{product_name}</p>
          <h6 className="product_price">
          {`₹${Number.isInteger(Number(price?.value)) ? Number(price?.value).toFixed(2) : Number(price?.value).toFixed(2)
          }`}
          </h6>
          <Button className="vw_cart" onClick={() => history.push(`/application/products?productId=${productId}`)}>
            Add to cart
          </Button>
          {/* <Button variant="primary">Go somewhere</Button> */}
        </Card.Body>
      </Card>


    
    </div>
  );
};

export default ProductGridView;
